import React, { useContext, useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import styled, { css } from "styled-components"
import Layout, { Headline } from "../../components/layout"
import bg from "../../assets/img/3005.jpg"
import { navigate } from "gatsby"
import { UserContext } from "../../components/user/user"
import axios from "axios"
import * as yup from "yup" // for everything
import { AsYouType } from "libphonenumber-js"

const API_URL = process.env.GATSBY_SES_API_URL

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  street: yup.string().required("Street address is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  zip: yup.string().required("Zip code is required"),
  phone: yup.string().required("Phone number is required"),
  email: yup
    .string()
    .required("Email is required")
    .email("Email should be a valid email address"),
})

const ContactForm = styled.form`
  .row {
    margin-top: 48px;

    > div {
      margin-top: 0;
      margin-bottom: 32px;
    }
  }

  input {
    line-height: 1;
    padding: 12px 16px 10px;
  }

  input[type="text"] {
    width: 100%;
    outline: none;
    border: 1px solid var(--lightGrey);

    &:focus {
      border-color: var(--brandSecondary);
    }
  }

  input[type="submit"],
  button {
    background: var(--brandPrimary);
    color: var(--white);
    outline: none;
    border: none;
    display: inline-block;
    margin-top: 12px;
    transition: background 0.15s;
    text-transform: uppercase;

    &:hover {
      background: #ffcc00;
      color: #000000;
    }
  }
`

const FieldSet = styled.div`
  position: relative;
`

const hiddenDivStyle = css`
  display: none;
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap; /* added line */
  border: 0;
`

const Error = styled.div`
  color: red;
`

const sharedInputStyles = css`
  font-size: 16px;
  padding: 5px;
  border: ${props => (props.error ? "1px solid red !important" : 0)};
`

const Input = styled.input`
  ${sharedInputStyles}
`

const Select = styled.select`
  font-size: 16px;
  padding: 5px;
  border: ${props => (props.error ? "1px solid red !important" : 0)};
  width: 100%;
`

const Checkbox = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
`

const Label = styled.label`
  font-size: 16px;
  display: block;
`

const Button = styled.button`
  font-size: 22px;
  font-weight: 700;
  padding: 20px;
`

const OptionallyHiddenDiv = styled.div`
  ${({ hasAddress = true }) => hasAddress && hiddenDivStyle}
`

export default function GetACashOffer() {
  const { place } = useContext(UserContext)

  /**
   * we only want to hide the fields if we have a parsed address ready to use.
   * `hasAddress` checks for place.address_components.
   */
  const [hasAddress, setHasAddress] = useState(!!place?.address_components)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [street, setStreet] = useState("")
  const [unit, setUnit] = useState("")
  const [city, setCity] = useState("")
  const [state, setState] = useState("")
  const [zip, setZip] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [source, setSource] = useState("")
  const [iAmHuman, setIAmHuman] = useState(false)
  const [errors, setErrors] = useState([])

  function gtag_report_conversion(url) {
    var callback = function () {
      if (typeof url != "undefined") {
        window.location = url
      }
    }
    window.gtag("event", "conversion", {
      send_to: "AW-376971454/KeYOCM2-udsCEL7B4LMB",
      event_callback: callback,
    })
    return false
  }

  const onSubmit = e => {
    e.preventDefault()
    const payload = {
      firstName,
      lastName,
      street,
      unit,
      city,
      state,
      zip,
      phone,
      email,
      iAmHuman,
      source,
    }
    schema.validate(payload, { strict: true, abortEarly: false }).then(
      valid => {
        if (valid) {
          gtag_report_conversion()
          onValidSubmit(payload)
        }
      },
      e => {
        setErrors(e.errors)
      }
    )
  }

  const onValidSubmit = data => {
    const url = `${API_URL}/email/send/form`
    try {
      axios
        .post(url, data)
        .then(response => response.data)
        .then(data => {
          navigate("/get-a-cash-offer/confirmation")
        })
    } catch (err) {
      console.error(err)
    }
  }

  const onPhoneChange = value => {
    if (!value) {
      return setPhone("")
    }
    // handle automatic partial formatting of number when deleting area code bracket
    if (value.includes("(") && !value.includes(")")) {
      return setPhone(value.replace("(", ""))
    }
    setPhone(new AsYouType("US").input(value))
  }

  /**
   * Pre-populate the available field values
   */
  useEffect(() => {
    if (place?.address_components) {
      const prefillData = parseParts(place.address_components)
      setStreet(prefillData.street)
      setCity(prefillData.city)
      setState(prefillData.state)
      setZip(prefillData.zip)
    }
    setHasAddress(!!place?.address_components)
  }, [place])
  return (
    <Layout bg={bg} hero={false}>
      <Helmet>
        <title>Get Cash for Homes in Southern California | Direct Buyers</title>
        <meta
          name="description"
          content="Direct Buyers are your trusted Southern California home buyers for any property! Sell your house for cash quickly by contacting our team today to request an offer."
        />
      </Helmet>
      <ContactForm onSubmit={onSubmit} className={`container`}>
        <Headline>
          <h1>Get A Cash Offer</h1>
        </Headline>
        <div className={`row`}>
          <div className={`col-md-8`}>
            <div className={`row`}>
              <div className={`col-md-6`}>
                <Checkbox
                  id="i-am-human"
                  name="iAmHuman"
                  type="checkbox"
                  value={iAmHuman}
                  onChange={setIAmHuman}
                />
                <FieldSet>
                  <Label htmlFor="first-name">First Name</Label>
                  <Input
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    id="first-name"
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                  />
                </FieldSet>
              </div>
              <div className={`col-md-6`}>
                <FieldSet>
                  <Label htmlFor="last-name">Last Name</Label>
                  <Input
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    id="last-name"
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                  />
                </FieldSet>
              </div>
              <OptionallyHiddenDiv
                hasAddress={hasAddress}
                className={`col-md-6`}
              >
                <FieldSet>
                  <Label htmlFor="street-address">Street Address</Label>
                  <Input
                    value={street}
                    onChange={e => setStreet(e.target.value)}
                    name="street"
                    id="street-address"
                    type={hasAddress ? "hidden" : "text"}
                    placeholder="Street Address"
                  />
                </FieldSet>
              </OptionallyHiddenDiv>
              <OptionallyHiddenDiv
                hasAddress={hasAddress}
                className={`col-md-6`}
              >
                <FieldSet>
                  <Label htmlFor="unit">Unit #</Label>
                  <Input
                    value={unit}
                    onChange={e => setUnit(e.target.value)}
                    name="unit"
                    id="unit"
                    type={hasAddress ? "hidden" : "text"}
                    placeholder="Unit #"
                  />
                </FieldSet>
              </OptionallyHiddenDiv>
              <OptionallyHiddenDiv
                hasAddress={hasAddress}
                className={`col-md-6`}
              >
                <FieldSet>
                  <Label htmlFor="city">City</Label>
                  <Input
                    value={city}
                    onChange={e => setCity(e.target.value)}
                    name="city"
                    id="city"
                    type={hasAddress ? "hidden" : "text"}
                    placeholder="City"
                  />
                </FieldSet>
              </OptionallyHiddenDiv>
              <OptionallyHiddenDiv
                hasAddress={hasAddress}
                className={`col-md-6`}
              >
                <FieldSet>
                  <Label htmlFor="state">State</Label>
                  <Input
                    value={state}
                    onChange={e => setState(e.target.value)}
                    name="state"
                    id="state"
                    type={hasAddress ? "hidden" : "text"}
                    placeholder="State"
                  />
                </FieldSet>
              </OptionallyHiddenDiv>
              <OptionallyHiddenDiv
                hasAddress={hasAddress}
                className={`col-md-6`}
              >
                <FieldSet>
                  <Label htmlFor="zip-code">Zip Code</Label>
                  <Input
                    value={zip}
                    onChange={e => setZip(e.target.value)}
                    name="zip"
                    id="zip-code"
                    type={hasAddress ? "hidden" : "text"}
                    placeholder="Zip Code"
                  />
                </FieldSet>
              </OptionallyHiddenDiv>
              <div className={`col-md-6`}>
                <FieldSet>
                  <Label htmlFor="phone">Phone</Label>
                  <Input
                    value={phone}
                    onChange={e => onPhoneChange(e.target.value)}
                    name="phone"
                    id="phone"
                    type="text"
                    placeholder="Phone"
                    maxLength={14}
                  />
                </FieldSet>
              </div>
              <div className={`col-md-6`}>
                <FieldSet>
                  <Label htmlFor="email">Email</Label>
                  <Input
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    name="email"
                    id="email"
                    type="text"
                    placeholder="Email"
                  />
                </FieldSet>
              </div>
              <div className={`col-md-12`}>
                <FieldSet>
                  <Label htmlFor="source">Where did you hear about us?</Label>
                  <Select
                    value={source}
                    onChange={e => setSource(e.target.value)}
                    name="source"
                    id="source"
                  >
                    <option value="">--Please choose an option--</option>
                    <option value="TV Commercial">TV Commercial</option>
                    <option value="Radio">Radio</option>
                    <option value="Social Media">Social Media</option>
                    <option value="Reviews">Reviews</option>
                    <option value="Friend/Referral">Friend/Referral</option>
                  </Select>
                </FieldSet>
              </div>
              {errors.length > 0 && (
                <div className="col-12">
                  <Error>{errors.map(error => `${error}. `)}</Error>
                </div>
              )}
              <div className={`col`}>
                <Button type="submit">Get My Cash Offer</Button>
              </div>
            </div>
          </div>
        </div>
      </ContactForm>
    </Layout>
  )
}

/**
 * We use this to iterate over the place data and attempt to pluck out available data
 * @type {{zip: [string], city: [string], street: [string, string], state: [string]}}
 */
const fieldsToPlaceTypes = {
  street: ["street_number", "route"],
  city: ["locality"],
  state: ["administrative_area_level_1"],
  zip: ["postal_code"],
}

const fields = ["street", "city", "state", "zip"]

function parseParts(parts) {
  return fields.reduce((data, field) => {
    const types = fieldsToPlaceTypes[field]
    const fieldData = types
      .map(kind => {
        const match = parts.filter(part => part.types.includes(kind))
        if (match.length >= 1) {
          return match[0].short_name
        }
        return null
      })
      .filter(Boolean)
      .join(" ")
    if (fieldData !== "") {
      data[field] = fieldData
    }
    return data
  }, {})
}
